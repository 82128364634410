<template>
  <Stack class="w-full aspect-square" v-if="image.length === 0">
    <img :src="IMAGES.PLACEHOLDER" sizes="1vw" fetchpriority="high" />
  </Stack>

  <div v-else class="w-full sm:w-auto">
    <!-- Mobile -->
    <div class="w-full md:hidden">
      <Stack class="relative w-full aspect-square" align="center" justify="center" v-if="image.length === 1">
        <img :src="image[0].url_standard" fetchpriority="high" class="w-full h-full object-contain" />
        <Icon
          name="zoom-in"
          class="absolute top-xs right-xs cursor-pointer p-[10px] bg-mkm-blue-default hover:bg-mkm-blue-light rounded-md text-white"
          :size="40"
          @click="openZoomModal(0)"
        />
      </Stack>

      <LazySlider
        v-else
        :slides-per-view="1"
        :space-between="0"
        :draggable="true"
        :autoplay="false"
        display-pagination-dots
        custom-dots-theme="orange-gray"
        :items="reversedImages"
        class="!w-full flex md:hidden flex-wrap justify-center"
      >
        <swiper-slide
          v-for="(galleryImage, index) in reversedImages"
          :key="index"
          :data-test-id="`GalleryItem-${index}`"
          class="m-auto bg-white !w-[100vw] md:w-full !flex justify-center items-center"
        >
          <Stack class="relative w-full aspect-square" align="center" justify="center">
            <img
              :src="galleryImage.url_standard"
              :fetchpriority="index === 0 ? 'high' : 'auto'"
              class="w-full h-full object-contain"
            />
            <Icon
              name="zoom-in"
              class="absolute top-xs right-xs sm:right-xl cursor-pointer p-[10px] bg-mkm-blue-default hover:bg-mkm-blue-light rounded-md text-white"
              :size="40"
              @click="openZoomModal(image.length - 1 - index)"
            />
          </Stack>
        </swiper-slide>
      </LazySlider>
    </div>

    <!-- Web -->
    <Stack direction="col" gap="xs" class="w-full !hidden md:!flex">
      <div class="relative w-full bg-white aspect-square flex items-center justify-center">
        <img
          :src="image[image.length - 1 - currentImageIndex].url_zoom"
          fetchpriority="high"
          sizes="1vw"
          alt=""
          class="max-w-full max-h-full"
        />
        <button aria-label="Click to zoom" @click="openZoomModal()">
          <Icon
            name="zoom-in"
            class="absolute top-sm right-sm cursor-pointer p-xs bg-mkm-blue-default hover:bg-mkm-blue-light rounded-md text-white"
            :size="48"
          />
        </button>
      </div>
      <Stack direction="row" gap="xs" v-if="image.length > 1" class="!hidden sm:!flex">
        <div
          v-for="(galleryImage, index) in reversedImages"
          :key="index"
          :class="[
            'w-[82px] h-[82px] bg-white flex items-center justify-center relative',
            'after:absolute after:top-none after:left-none after:w-full after:h-full after:bg-charcoal-darker after:cursor-pointer',
            'after:opacity-0 after:hover:opacity-30 after:transition-all after:duration-200 after:ease-in-out',
            { 'border-md border-mkm-blue-light': currentImageIndex === index },
          ]"
          @click="currentImageIndex = index"
        >
          <img :key="index" :src="galleryImage.url_thumbnail" class="max-h-[80px] object-contain" />
        </div>
      </Stack>
    </Stack>
  </div>

  <!-- Product Zoomer Modal -->
  <ProductZoomer
    v-if="showOverlay"
    :images="reversedImages"
    @close="showOverlay = false"
    :currentImageIndex="currentImageIndex"
  />
</template>

<script lang="ts" setup>
const SwiperSlide = defineAsyncComponent(() => import("swiper/vue").then((module) => module.SwiperSlide));
import { IMAGES } from "@/constants/images";

const props = defineProps(["image"]);

const showOverlay = ref<boolean>(false);
const currentImageIndex = ref<number>(0);

const reversedImages = computed(() => props.image.slice().reverse());

const openZoomModal = (index?: number) => {
  showOverlay.value = true;
  if (index) currentImageIndex.value = index;
};
</script>
