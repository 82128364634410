// import { toRefs } from '@vueuse/shared';
import type { UseProductState, FetchProduct } from "./types";
import { createFindItemQueryString } from "@/helpers/queries/findItemQueryBuilder";

export const useProduct = (sku: string) => {
  const state = useState<UseProductState>(`useProduct-${sku}`, () => ({
    data: null,
    productDetails: null,
    loading: false,
  }));

  const { BR_ENV_TYPE } = useRuntimeConfig().public;
  const { selectedBranchId } = useBranches();
  const bloomreachCookie = useCookie("_br_uid_2");
  const viewId = computed(() => (selectedBranchId.value ? `b_${selectedBranchId.value}` : "default"));
  const brUid = computed(() => (bloomreachCookie ? bloomreachCookie.value : "noBrCookie"));

  const fetchProduct: FetchProduct = async () => {
    state.value.loading = true;

    const productQuery = createFindItemQueryString({
      id: sku,
      viewId: viewId.value,
      brEnvType: BR_ENV_TYPE === "STAGING" ? "STAGING" : undefined,
      brUid2: brUid.value ?? undefined,
    });

    const { data, error } = await useTimedAsyncData("federatedProduct", () =>
      useSdk().bcAPI.fetchFederatedProducts({ skus: [sku], query: productQuery }),
    );

    if (error.value || !data.value || (Array.isArray(data.value) && data.value.length === 0)) {
      throw createError({
        statusCode: 404,
        message: "Product not found",
        statusMessage: "No product data found",
        fatal: true,
      });
    }

    state.value.data = data.value.products;
    state.value.productDetails = data.value.productDetails;
    state.value.loading = false;
    return data;
  };

  return {
    fetchProduct,
    ...toRefs(state.value),
  };
};

export default useProduct;
