/**
 * Configuration options for the find-item GraphQL query.
 */
export interface FindItemQueryOptions {
  /**
   * The item ID to be queried. Defaults to "B001323".
   */
  id: string;
  /**
   * The view ID for the queryHint. Defaults to "b_2".
   */
  viewId: string;
  /**
   * Environment type for the queryHint. Defaults to "STAGING".
   */
  brEnvType?: string;
  /**
   * UID2 if you need it. Defaults to undefined.
   */
  brUid2?: string;
}

/**
 * Creates a GraphQL query string (with no external variables)
 * by interpolating the dynamic parts directly in the query text.
 *
 * @param {FindItemQueryOptions} [options] - Optional overrides for the query.
 * @returns {string} - A fully-formed GraphQL query string.
 */
export function createFindItemQueryString(options: FindItemQueryOptions): string {
  const { id, viewId, brEnvType, brUid2 } = options;

  // If brUid2 is undefined, we omit it from the queryHint block
  const brUid2Line = brUid2 ? `brUid2: "${brUid2}",` : "";

  // Typical customAttrFields we want in the queryHint
  const customAttrFields: string[] = [
    "brand",
    "url",
    "is_tally",
    "price",
    "price_inc_vat",
    "offer_price",
    "offer_price_inc_vat",
    "availability_flag",
    "availability_reason",
    "cf_width",
    "unit",
    "offer_unit",
    "cf_thickness",
    "cf_treatment",
    "cf_grade",
  ];

  // In Production, the brEnvType will come through as undefined. We do not want the key value in the object
  // so we strip it out.
  const queryHint = {
    viewId: viewId,
    ...(brEnvType && { brEnvType }),
    customAttrFields: [
      "brand",
      "url",
      "is_tally",
      "price",
      "price_inc_vat",
      "offer_price",
      "offer_price_inc_vat",
      "availability_flag",
      "availability_reason",
      "cf_width",
      "unit",
      "offer_unit",
      "cf_thickness",
      "cf_treatment",
      "cf_grade",
    ],
  };

  // Convert customAttrFields to a comma-separated list of string literals
  const customAttrFieldsString = customAttrFields.map((field) => `"${field}"`).join(", ");

  const queryHintString = `
    viewId: "${queryHint.viewId}",
    ${queryHint.brEnvType ? `brEnvType: "${queryHint.brEnvType}",` : ""}
    ${brUid2Line}
    customAttrFields: [${customAttrFieldsString}]
  `.trim();

  // Now build the GraphQL query using string interpolation
  // Note that we are not using a GraphQL "variables" approach,
  // but simply injecting the dynamic values directly into the string.
  return `#graphql
    query findItemById {
      findItemById(
        id: "${id}",
        queryHint: {
          ${queryHintString}
        }
      ) {
        slug
        itemId {
          id
        }
        displayName
        description
        purchasePrice {
          moneyAmounts {
            amount
            currency
            displayValue
          }
        }
        listPrice {
          moneyAmounts {
            amount
            currency
            displayValue
          }
        }
        imageSet {
          original {
            dimension {
              w
              h
            }
            link {
              href
              ref
            }
          }
          thumbnail {
            dimension {
              w
              h
            }
            link {
              href
              ref
            }
          }
        }
        salePriceRange
        priceRange
        customAttrs {
          name
          values
        }
        variants {
          itemId {
            id
          }
          displayName
          description
          master
          imageSet {
            original {
              dimension {
                w
                h
              }
              link {
                href
                ref
              }
            }
            thumbnail {
              dimension {
                w
                h
              }
              link {
                href
                ref
              }
            }
          }
          purchasePrice {
            moneyAmounts {
              amount
              currency
              displayValue
            }
          }
          listPrice {
            moneyAmounts {
              amount
              currency
              displayValue
            }
          }
          mainItem {
            itemId {
              id
            }
            displayName
          }
          customAttrs {
            name
            values
          }
          availability {
            inStock
            availableQuantity
            restockableInDays
          }
        }
      }
    }
  `;
}
