<template>
  <section @touchmove.prevent class="fixed top-none left-none w-screen h-screen bg-charcoal-darker bg-opacity-50 z-50">
    <div
      class="absolute w-full sm:w-4/5 md:w-3/4 lg:w-[950px] transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2"
    >
      <div class="relative p-xs md:p-md lg:p-lg bg-white rounded-xs">
        <Stack
          gap="xs"
          align="start"
          direction="col-reverse"
          :breakpoints="{ direction: { md: 'row' }, gap: { md: 'xl' } }"
        >
          <!-- thumbnails -->
          <Stack direction="row" gap="xs" :breakpoints="{ direction: { md: 'col' } }">
            <Stack
              v-for="(galleryImage, index) in images"
              :key="index"
              align="center"
              justify="center"
              class="rounded-sm cursor-pointer h-[80px] w-3xl"
              :class="{ 'border-md border-mkm-blue-light': overlayIndex === index }"
              @click="overlayIndex = index"
            >
              <img :key="index" :src="galleryImage.url_thumbnail" class="max-h-[76px]" />
            </Stack>
          </Stack>

          <!-- zooming image box -->
          <Stack
            justify="center"
            align="center"
            class="group/box relative w-full md:w-4/5 h-[50vh] md:h-[80vh] overflow-hidden shadow-sm image-container rounded-sm transition-all duration-500 ease-in-out"
            ref="imageContainer"
            @mousemove="handleCursorMove"
            @touchstart="handleTouchStart"
          >
            <img
              :src="images[overlayIndex].url_zoom"
              class="absolute object-cover original-image cursor-zoom-in rounded-sm max-h-[80%]"
            />
            <Text
              size="sm"
              class="group-hover/box:hidden uppercase text-blue-default bg-white !w-fit px-xs py-2xs rounded-sm absolute bottom-2xs left-1/2 -translate-x-1/2"
              weight="semi"
            >
              Hover to Zoom
            </Text>
          </Stack>
        </Stack>

        <Icon
          name="times-thin"
          :size="36"
          class="absolute -top-xs md:top-2xs right-1/2 translate-x-1/2 sm:translate-x-none sm:-right-xs md:right-2xs p-2xs rounded-sm bg-white shadow-xs cursor-pointer"
          @click="closeOverlay"
        />
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
const emit = defineEmits(["close"]);

const props = defineProps<{
  currentImageIndex: number;
  images: any[];
}>();

const overlayIndex = ref<number>(props.currentImageIndex);

const closeOverlay = () => {
  emit("close");
};

// Handling the cursor movement for web clients
const handleCursorMove = (e: MouseEvent) => {
  const imageContainer = document.querySelector(".image-container") as HTMLElement;
  if (!imageContainer) return;

  const { left, top, width, height } = imageContainer.getBoundingClientRect();

  // cursor position offset within the image zooming container
  const x = e.clientX - left;
  const y = e.clientY - top;

  // percentage of the cursor position within the image zooming container
  const horizontalShiftPercentage = (x / width) * 100;
  const verticalShiftPercentage = (y / height) * 100;

  // change the origin of the zoomed image ( scaled image )
  imageContainer?.style.setProperty("--newOriginX", `${horizontalShiftPercentage}%`);
  imageContainer?.style.setProperty("--newOriginY", `${verticalShiftPercentage}%`);
};

// Handling the dragging of the zoomed image on mobile clients
const handleTouchStart = (e: TouchEvent) => {
  const imageContainer = document.querySelector(".image-container") as HTMLElement;
  if (!imageContainer) return;

  const { clientX: initialX, clientY: initialY } = e.touches[0];

  const startScrollLeft = imageContainer.scrollLeft;
  const startScrollTop = imageContainer.scrollTop;

  const containerWidth = imageContainer.clientWidth;
  const containerHeight = imageContainer.clientHeight;

  const handleTouchMove = (e: TouchEvent) => {
    const { clientX: currentX, clientY: currentY } = e.touches[0];

    const xDiff = currentX - initialX;
    const yDiff = currentY - initialY;

    // Calculate new scroll positions
    const newScrollLeft = Math.max(0, Math.min(startScrollLeft - xDiff, imageContainer.scrollWidth - containerWidth));
    const newScrollTop = Math.max(0, Math.min(startScrollTop - yDiff, imageContainer.scrollHeight - containerHeight));

    imageContainer.scrollLeft = newScrollLeft;
    imageContainer.scrollTop = newScrollTop;

    const newCenterX = newScrollLeft + containerWidth / 2;
    const newCenterY = newScrollTop + containerHeight / 2;

    // Calculate new origin percentage
    const horizontalShiftPercentage = (newCenterX / imageContainer.scrollWidth) * 100;
    const verticalShiftPercentage = (newCenterY / imageContainer.scrollHeight) * 100;

    imageContainer.style.setProperty("--newOriginX", `${horizontalShiftPercentage}%`);
    imageContainer.style.setProperty("--newOriginY", `${verticalShiftPercentage}%`);
  };

  const handleTouchEnd = () => {
    imageContainer.removeEventListener("touchmove", handleTouchMove);
    imageContainer.removeEventListener("touchend", handleTouchEnd);
  };

  imageContainer.addEventListener("touchmove", handleTouchMove);
  imageContainer.addEventListener("touchend", handleTouchEnd);
};
</script>

<style>
.original-image {
  transform: scale(var(--zoomLevel, 1));
  transform-origin: var(--newOriginX, 50%) var(--newOriginY, 50%);
  transition: transform 0.3s ease;
}

.image-container:hover {
  --zoomLevel: 3;
}
</style>
