<template>
  <div
    class="product-details text-charcoal-default"
    :data-test-id="productDetailsTestId('productDetailContent')"
    data-fs="productDetailContent"
  >
    <Stack direction="col" gap="sm" :breakpoints="{ gap: { md: 'md' } }">
      <div v-if="product && product[0]">
        <Heading
          size="xs"
          is="h1"
          class="my-[6px]"
          :data-test-id="productDetailsTestId('productNamePdp')"
          data-fs="productNamePdp"
        >
          {{ product[0].name }}
        </Heading>
        <Text is="p" size="sm" :data-test-id="productDetailsTestId('productCode')" data-fs="productCode">
          Product code: {{ product[0].sku }}
        </Text>
      </div>

      <div v-if="containsSustainabilityIcon">
        <SustainabilityIcons v-if="product" :custom-fields="product[0].custom_fields" />
      </div>

      <!-- flag 1, 2-->
      <Stack
        v-if="availabilityFlag !== '4' && availabilityFlag !== '3'"
        align="start"
        gap="xs"
        justify="between"
        direction="col"
        class="w-full border-b-sm border-t-sm py-md border-grey-light"
        :breakpoints="{ direction: { md: 'row' }, gap: { md: 'none' } }"
      >
        <Stack align="start" class="!w-full md:!w-2/5">
          <Icon name="truck" :size="16" class="mt-3xs" />
          <Stack direction="col" gap="none" :data-test-id="productDetailsTestId('freeDelivery')" data-fs="freeDelivery">
            <Text size="sm" weight="bold">FREE local delivery</Text>
            <Text size="sm">Restrictions apply*</Text>
          </Stack>
        </Stack>
        <Stack v-if="selectedBranch" align="start" class="!w-full md:!w-3/5">
          <Icon name="pointer-blue" :size="16" class="mt-3xs" />
          <Stack direction="col" gap="none">
            <Text size="sm" weight="bold">FREE Click & Collect from {{ selectedBranch.name }} </Text>
            <Stack align="baseline" gap="none">
              <div
                :class="[
                  'w-[10px] h-[10px] mr-2xs rounded-round',
                  availabilityFlag === '2' ? 'bg-orange-default' : 'bg-green-default',
                ]"
              />
              <Text size="sm" :data-test-id="productDetailsTestId('availability')" data-fs="availability" class="wrap">
                {{ availabilityReason }}
                <Text
                  v-if="immediatelyCollectable"
                  weight="bold"
                  size="sm"
                  class="ui-text-mkm-blue-light cursor-pointer ui-bg-transparent ui-z-10"
                  @click="setBranchTray()"
                >
                  See opening hours
                </Text>
              </Text>
            </Stack>
          </Stack>
        </Stack>
      </Stack>

      <section v-if="availabilityFlag === '4'" class="border-b-sm border-t-sm py-md border-grey-light !w-full">
        <Stack align="start" gap="xs">
          <Icon name="package" :size="16" class="mt-3xs" />
          <Stack direction="col" gap="none" :data-test-id="productDetailsTestId('freeDelivery')" class="!w-full">
            <Text size="sm" weight="bold">FREE delivery available</Text>
            <Text size="sm">{{ availabilityReason }} </Text>
            <Text
              size="sm"
              data-test-id="findOutMore"
              data-fs="findOutMore"
              @click="setDropshipTray"
              weight="bold"
              class="cursor-pointer text-mkm-blue-light"
            >
              Find out more
            </Text>
          </Stack>
        </Stack>
      </section>

      <section
        v-if="selectedBranch && availabilityFlag === '3'"
        class="border-b-sm border-t-sm py-md border-grey-light w-full"
      >
        <CardUSP
          availability="outOfStock"
          :availability-reason="availabilityReason"
          :branch="selectedBranch.name"
          :branch-number="selectedBranch.contacts.phone"
          :data-test-id="productDetailsTestId('outOfStock')"
          data-fs="pdpOutOfStock"
        />
      </section>

      <Stack v-if="availabilityFlag !== '2' && availabilityFlag !== '3'" direction="col" align="start">
        <Text v-if="selectedBranch && !user">
          <button
            @click="setLoginTray"
            class="text-mkm-blue-light font-bold"
            :data-test-id="productDetailsTestId('signInLink')"
            data-fs="signInLink"
          >
            Sign in
          </button>
          for your account price</Text
        >

        <!-- Show Default BR Price -->
        <Stack v-if="!user && selectedBranch" direction="col" gap="2xs" align="start">
          <Stack gap="2xs" align="baseline">
            <Text size="lg" weight="bold" :data-test-id="productDetailsTestId('pricePerUnit')" data-fs="pricePerUnit">
              {{ toCurrency({ value: Number(isVatIncluded ? priceVatInc : priceVatExc) }) }}
            </Text>
            <Text size="caption" weight="bold" class="nowrap">
              {{ productUnitTranslation(`${unit}` as ProductDetailsType) }}
            </Text>
            <Text size="caption" class="nowrap" :data-test-id="productDetailsTestId('vat')" data-fs="vat">
              {{ isVatIncluded ? "inc. VAT" : "ex. VAT" }}
            </Text>
          </Stack>
          <Stack v-if="offerPrice !== priceVatExc" gap="2xs" align="baseline">
            <Text
              size="md"
              weight="semi"
              class="text-charcoal-light"
              :data-test-id="productDetailsTestId('productPrice')"
              data-fs="productPrice"
              >{{ toCurrency({ value: Number(isVatIncluded ? offerPriceIncVat : offerPrice) }) }}
            </Text>
            <Text
              size="xs"
              weight="semi"
              class="nowrap text-charcoal-light"
              :data-test-id="productDetailsTestId('offerUnit')"
              data-fs="offerUnit"
              >{{ productUnitTranslation(`${offerUnit}` as ProductDetailsType) }}</Text
            >
          </Stack>
        </Stack>

        <!-- Show User Specific Price -->
        <Stack v-if="user && price" direction="col" gap="2xs" align="start">
          <Stack align="center" gap="none" :data-test-id="productDetailsTestId('yourPrice')" data-fs="yourPrice">
            <Text v-if="user" weight="bold">{{ user.profile.first_name }}</Text>
            <Text class="nowrap">&nbsp;your price is</Text>
          </Stack>
          <Stack gap="2xs" align="baseline">
            <Text size="3xl" weight="bold" :data-test-id="productDetailsTestId('pricePerUnit')" data-fs="pricePerUnit">
              {{ toCurrency({ value: isVatIncluded ? price.price_inc_vat : price.price }) }}
            </Text>
            <Text size="caption" weight="bold" class="nowrap">
              {{ productUnitTranslation(`${unit}` as ProductDetailsType) }}
            </Text>
            <Text size="caption" class="nowrap" :data-test-id="productDetailsTestId('vat')" data-fs="vat">
              {{ isVatIncluded ? "inc. VAT" : "ex. VAT" }}
            </Text>
          </Stack>
          <Stack gap="2xs" align="baseline" v-if="offerPrice !== priceVatExc">
            <Text
              size="md"
              weight="semi"
              class="text-charcoal-light"
              :data-test-id="productDetailsTestId('productPrice')"
              data-fs="productPrice"
            >
              {{ toCurrency({ value: isVatIncluded ? price.offer_price_inc_vat : price.offer_price }) }}
            </Text>
            <Text
              size="xs"
              weight="semi"
              class="nowrap text-charcoal-light"
              :data-test-id="productDetailsTestId('offerUnit')"
            >
              {{ productUnitTranslation(`${offerUnit}` as ProductDetailsType) }}
            </Text>
          </Stack>
        </Stack>

        <!-- User No Terms -->
        <Stack direction="col" gap="2xs" align="start" v-if="user && !price">
          <Stack align="center" gap="none" :data-test-id="productDetailsTestId('yourPrice')" data-fs="yourPrice">
            <Text v-if="user" weight="bold">{{ user.profile.first_name }}</Text>
            <Text class="nowrap">&nbsp;your price is</Text>
          </Stack>
          <Stack gap="2xs" align="baseline">
            <Text size="lg" weight="bold" :data-test-id="productDetailsTestId('pricePerUnit')" data-fs="pricePerUnit">
              {{ toCurrency({ value: Number(isVatIncluded ? priceVatInc : priceVatExc) }) }}
            </Text>
            <Text size="caption" weight="bold" class="nowrap">
              {{ productUnitTranslation(`${unit}` as ProductDetailsType) }}
            </Text>
            <Text size="caption" class="nowrap" :data-test-id="productDetailsTestId('vat')" data-fs="vat">
              {{ isVatIncluded ? "inc. VAT" : "ex. VAT" }}
            </Text>
          </Stack>

          <Stack v-if="offerPrice !== priceVatExc" gap="2xs" align="baseline">
            <Text
              size="md"
              weight="semi"
              class="text-charcoal-light"
              :data-test-id="productDetailsTestId('productPrice')"
              data-fs="productPrice"
              >{{ toCurrency({ value: Number(isVatIncluded ? offerPriceIncVat : offerPrice) }) }}
            </Text>
            <Text
              size="xs"
              weight="semi"
              class="nowrap text-charcoal-light"
              :data-test-id="productDetailsTestId('offerUnit')"
              data-fs="offerUnit"
            >
              {{ productUnitTranslation(`${offerUnit}` as ProductDetailsType) }}
            </Text>
          </Stack>
        </Stack>

        <!-- Show Select Branch Button -->
        <Stack v-if="!selectedBranch" direction="col" gap="xs">
          <Button
            size="md"
            variant="secondary"
            :data-test-id="productDetailsTestId('selectBranchBtn')"
            @click="handleBranchSelect"
            data-fs="pdpBranchSelect"
          >
            Select a branch
          </Button>
          <Text>to see available stock and prices</Text>
        </Stack>
      </Stack>

      <Stack
        v-if="availabilityFlag === '2'"
        direction="col"
        gap="xs"
        class="border-xl border-mkm-blue-default p-xs"
        :data-test-id="productDetailsTestId('quoteEnquiry')"
        data-fs="pdpQuoteEnquiry"
      >
        <Text size="sm">
          We’re really sorry but this item cannot be ordered on the website today. However, give us a call and we can
          discuss how we can get this or something very similar for you as quick as possible.
        </Text>
        <Text v-if="selectedBranch" weight="bold" size="sm">
          Call MKM {{ selectedBranch.name }} on
          <a :href="'tel:' + selectedBranch.contacts.phone" class="cursor-pointer text-mkm-blue-light">
            {{ selectedBranch.contacts.phone }}
          </a>
        </Text>
      </Stack>

      <Stack
        v-if="selectedBranch && !isTally && availabilityFlag !== '2' && availabilityFlag !== '3'"
        gap="sm"
        align="start"
        class="nowrap w-full md:w-3/5"
        direction="col"
        :breakpoints="{ direction: { lg: 'row' }, align: { lg: 'center' }, gap: { md: 'md' } }"
      >
        <CardQuantityControl
          @update:quantity="handleQuantityUpdate"
          :value="productQuantity"
          :data-test-id="productDetailsTestId('CardQuantityControl')"
        />
        <Button
          size="lg"
          class="!w-full flex justify-center text-center"
          :disabled="isAdding || productQuantity === 0"
          :data-test-id="productDetailsTestId('addToBasket')"
          @click="addItemToBasket"
          data-fs="pdpAddToBasketButton"
        >
          ADD TO BASKET
          <Spinner v-show="isAdding" />
        </Button>
      </Stack>
      <Stack
        v-if="selectedBranchId && isTally && availabilityFlag !== '3'"
        gap="md"
        align="start"
        class="nowrap w-full md:w-3/5"
        direction="col"
        :breakpoints="{ direction: { lg: 'row' }, align: { lg: 'center' } }"
      >
        <Button
          size="lg"
          variant="secondary"
          :data-test-id="productDetailsTestId('chooseQuantity')"
          data-fs="pdpChooseQuantity"
          @click="setTallyTray"
        >
          CHOOSE QUANTITY
        </Button>
      </Stack>
    </Stack>
  </div>
</template>
<script lang="ts" setup>
import { toCurrency } from "mkm-avengers";
import { productUnitTranslation, type ProductDetailsType } from "~/utils/productUnitTranslation";

const { user } = useUser();
const { selectedBranchId, selectedBranch } = useBranches();
const { setTallyTray, setDropshipTray, setBasketTray, setLoginTray } = useUIState();

const priceVatInc = ref<string>("");
const priceVatExc = ref<string>("");
const offerPrice = ref<string>("");
const offerPriceIncVat = ref<string>("");
const unit = ref<string>("");
const offerUnit = ref<string>("");
const availabilityFlag = ref<string>("");
const availabilityReason = ref<string>("");

const isTally = ref<boolean>(false);
const { isVatIncluded } = useUIState();
const { setAutoBranchSelect } = useUIState();
const { setBranchTray } = useUIState();
const productQuantity = ref<number>(1);
const { addCartItemQuantity } = useCart();
const isAdding = ref<boolean>(false);

const props = defineProps<{
  product: any;
  price: any;
  productDetails: any;
  productDetailsViewMobile: boolean;
}>();

const productDetailsTestId = (id: string) => (props.productDetailsViewMobile ? `mobile-${id}` : id);

const immediatelyCollectable = computed(() => availabilityReason.value.includes("1 hour"));

const containsSustainabilityIcon = computed(() => {
  if (props.product && Array.isArray(props.product) && props.product.length > 0) {
    const product = props.product[0];

    if (product.custom_fields && Array.isArray(product.custom_fields))
      return product.custom_fields.some((obj: any) => obj.name.includes("icon"));
  }
  return false;
});

const handleQuantityUpdate = (quantity: number) => (productQuantity.value = quantity);

function extractAttributeValue(objects: any[], attributeName: string): any {
  if (Array.isArray(objects)) {
    const objectWithAttribute = objects.find((obj) => obj.name === attributeName);
    return objectWithAttribute ? objectWithAttribute.values[0] : null;
  }
  return null;
}

const fetchProductDetailsAndSetPrices = () => {
  if (!props.productDetails || !Array.isArray(props.productDetails.customAttrs)) return;

  const priceIncVatAttr = extractAttributeValue(props.productDetails.customAttrs, "price_inc_vat");
  priceVatInc.value = priceIncVatAttr || "";

  const priceExcVatAttr = extractAttributeValue(props.productDetails.customAttrs, "price");
  priceVatExc.value = priceExcVatAttr || "";

  const getOfferPrice = extractAttributeValue(props.productDetails.customAttrs, "offer_price");
  offerPrice.value = getOfferPrice || "";

  const getOfferPriceVat = extractAttributeValue(props.productDetails.customAttrs, "offer_price_inc_vat");
  offerPriceIncVat.value = getOfferPriceVat || "";

  const getIsTally = extractAttributeValue(props.productDetails.customAttrs, "is_tally");
  isTally.value = getIsTally !== "0";

  const getUnit = extractAttributeValue(props.productDetails.customAttrs, "unit");
  unit.value = getUnit || "";

  const getUnitOfferPrice = extractAttributeValue(props.productDetails.customAttrs, "offer_unit");
  offerUnit.value = getUnitOfferPrice || "";

  const getAvailabilityFlag = extractAttributeValue(props.productDetails.customAttrs, "availability_flag");
  availabilityFlag.value = getAvailabilityFlag || "";

  const getAvailabilityReason = extractAttributeValue(props.productDetails.customAttrs, "availability_reason");
  availabilityReason.value = getAvailabilityReason || "";
};

fetchProductDetailsAndSetPrices();

const handleBranchSelect = () => {
  setAutoBranchSelect(false);
  setBranchTray();
};

const addItemToBasket = async () => {
  isAdding.value = true;
  await addCartItemQuantity(props.product[0].sku, productQuantity.value)
    .then(() => (isAdding.value = false))
    .then(() => setBasketTray(true));
};

watch(selectedBranchId, (newBranchId) => {
  if (newBranchId) fetchProductDetailsAndSetPrices();
});

watch(() => props.productDetails.customAttrs, fetchProductDetailsAndSetPrices, { immediate: true });
</script>

<style>
@media (min-width: 768px) {
  .product-details {
    position: sticky;
    top: 16px;
    width: 50%;
  }
}
.product-details {
  background-color: white;
  width: 100%;
  padding: 25px;
  box-shadow: 0 4px 3px rgba(0, 0, 0, 0.25);
  border: 1px solid #f0f0f0;
}
</style>
