<template>
  <Stack
    class="w-full bg-white shadow-xs p-xs cursor-pointer rounded-sm hover:outline hover:outline-1 hover:outline-mkm-blue-light"
    justify="between"
    align="center"
    @click="setSpecificationsTray"
    data-test-d="specificationsButton"
    data-fs="specificationsButton"
  >
    <Heading size="xxs">Specifications</Heading>
    <Icon name="chevron-right" :size="16" />
  </Stack>
</template>

<script lang="ts" setup>
const { setSpecificationsTray } = useUIState();
</script>
